import { useLazyQuery } from "@apollo/react-hooks";
import LuxonUtils from "@date-io/luxon";
import { Button, Grid, MenuItem, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { DatePicker, DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import crypto from "crypto";
import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { connect } from "react-redux";
import { GetSubVertical } from "../../../common/models/types/GetSubVertical";
import { GetVertical } from "../../../common/models/types/GetVertical";
import { GET_SUBVERTICAL, GET_VERTICAL } from "../../../common/models/vertical";
import { withinPast } from "../../../common/utils/date";
import { RootState } from "../../../state";
import { DataCacheState, updateSubVerticals, updateVerticals } from "../../../state/dataCacheReducer";
import { updateForm } from "../../../state/formReducer";
import { ReportOption, ReportOptionParam } from "../../../types/reports";

interface Props {
    dispatch?: Function;
    reportsTabs?: any;
    formState?: any;
    formId: string;
    entityDataCache?: DataCacheState;
}

interface OptionProps {
    label: string;
    value: any;
    parent?: number;
}

const TAB_CONTAINER = "reports-list-tabs";

/** this is built with dynamic options for the future */
// const reportFields:ReportField = ;

// const currentTime = DateTime.local().toFormat("yyyy-MM-dd HH:mm:ss");
const currentTime = DateTime.local().toFormat("MMM dd, yyyy hh:mm:ss a")
const currentDate = DateTime.local().toFormat("yyyy-MM-dd");

const ReportsSearchForm = ({ reportsTabs, formState, dispatch, formId, entityDataCache }: Props) => {

    const { register, handleSubmit, watch, errors, control, getValues } = useForm();
    const [selectedReport, setSelectedReport] = useState<ReportOption<any>>();
    // const [reportMethodParams, setReportMethodParams] = useState<any>({});
    const [reportFormInputFields, setReportFormInputFields] = useState<JSX.Element[]>([]);
    // const [reportRequestId, setReportRequestId] = useState<string>("");
    // const [reportData, setReportData] = useState<any>(null);

    const [VerticalList, setVerticalList] = useState<OptionProps[]>([]);
    const [SubVerticalList, setSubVerticalList] = useState<OptionProps[]>([]);

    const [selectedVertical, setSelectedVertical] = useState<OptionProps | null>(null);
    const [selectedSubVertical, setSelectedSubVertical] = useState<OptionProps | null>(null);

    const [
        getAllVertical,
        { data: verticalData, error: verticalError, loading: verticalLoading },
    ] = useLazyQuery<GetVertical>(GET_VERTICAL);

    const [
        getAllSubVertical,
        {
            data: subVerticalData,
            error: subVerticalError,
            loading: subVerticalLoading,
        },
    ] = useLazyQuery<GetSubVertical>(GET_SUBVERTICAL);

    /** begin loading selection values */
    useEffect(() => {
        if (
        !entityDataCache?.ActiveVerticals ||
        (entityDataCache?.ActiveVerticals &&
            !withinPast(entityDataCache?.ActiveVerticals.time, { days: 1 }))
        ) {
            getAllVertical({ variables: { where: "IsActive = true" } });
        }
    }, []);

    /** vertical option list */
    useEffect(() => {
        if (verticalData && !verticalError) {
        dispatch &&
            dispatch(
                updateVerticals(verticalData?.LDPConfigQueryGroup?.Vertical || [])
            );
        }
    }, [verticalData, verticalError]);

    // update vertical options list when data cache is loaded
    useEffect(() => {
        if (!entityDataCache?.ActiveVerticals) return;

        setVerticalList(
            entityDataCache?.ActiveVerticals?.data?.map((it) => ({
                label: `${it?.VerticalName}`,
                value: it?.VerticalId,
            })) ?? []
        );

        if ( !entityDataCache?.ActiveSubVerticals || 
            (entityDataCache?.ActiveSubVerticals && !withinPast(entityDataCache?.ActiveSubVerticals.time, { days: 1 }))
        ) {
            getAllSubVertical({ variables: { where: "IsActive = true" } });
        }
    }, [entityDataCache?.ActiveVerticals]);

    useEffect(() => {
        // console.log('VerticalList', VerticalList);
        if(VerticalList.length > 0 && !selectedVertical){
            setSelectedVertical(VerticalList[0]);
        }
    }, [VerticalList]);

    useEffect(() => {
        if (subVerticalData && !subVerticalError) {
            dispatch &&
            dispatch(
                updateSubVerticals(
                    subVerticalData?.LDPConfigQueryGroup?.SubVertical || []
                )
            );
        }
    }, [subVerticalData, subVerticalError]);

    /** sub-vertical option list */
    useEffect(() => {
        // console.log('selectedVertical', selectedVertical);
        if (selectedVertical && entityDataCache?.ActiveSubVerticals) {
            const filteredSubVerticals =
                entityDataCache?.ActiveSubVerticals?.data?.filter(
                (it) => it?.VerticalId === selectedVertical?.value
                ) ?? [];

            if (filteredSubVerticals?.length > 0) {
                const subs = filteredSubVerticals?.map((it) => ({
                    label: `${it?.SubVerticalName}`,
                    value: it?.SubVerticalId,
                }));

                setSubVerticalList(subs);
                setSelectedSubVertical(subs[0]);
            }
        }
    }, [selectedVertical, entityDataCache?.ActiveSubVerticals]);

    useEffect(() => {
        // console.log('SubVerticalList', SubVerticalList);
        if(SubVerticalList && SubVerticalList.length > 0){
            setSelectedSubVertical(SubVerticalList[0]);
        }
    }, [SubVerticalList]);

    /* useEffect(() => {
        console.log('selectedSubVertical', selectedSubVertical);
    }, [selectedSubVertical]); */

    useEffect(()=>{
        if(formState){
            setSelectedReport(formState.formData);
            setReportFormInputFields(formState.formData.Params.map((param: ReportOptionParam, index:any) => {
                return <Grid item xs={12} md={3} lg={3} key={`d-input-${index}`}>{renderOptionParam(param)}</Grid>
            }));
        }
    }, [formState, VerticalList, SubVerticalList, selectedVertical, selectedSubVertical]);

    const renderOptionParam = (optionParam:ReportOptionParam) => {
        // console.log("renderOptionParam", optionParam);
        const fieldName = `${optionParam.Name.replaceAll(/\s/g, "")}`;
        const fieldId = `${formId}-${fieldName}-input`;
        
        // console.log("here");
        if (optionParam.Type === 'bool') {            
            return (
                <Controller
                    render={({ ref, ...rest }) => {
                        return <TextField
                        {...rest}
                        id={fieldId}
                        key={`${formId}_${fieldName}`}
                        label={optionParam?.Name}                
                        variant="outlined"                                                 
                        fullWidth
                        select
                    >
                        {optionParam.Options?.map((it:any, n:number) => (<MenuItem key={n} value={it}>{it ? 'Yes' : 'No'}</MenuItem>))};
                    </TextField>
                    }}
                    name={fieldName}
                    control={control}
                    defaultValue={optionParam.Value ?? false}
                />
            );
        } else if(optionParam.Type === "string" && optionParam.Options){
            if(optionParam.Name === "VerticalName"){
                return <Autocomplete
                    id={fieldId}
                    key={`${formId}_${fieldName}`}
                    onChange={(evt, value) => {
                        setSelectedVertical(
                            value
                        );
                    }}
                    getOptionLabel={option => `${option.label} - ${option.value}`}
                    options={VerticalList}
                    value={selectedVertical}
                    renderInput={params => (
                        <TextField
                            {...params}
                            label={`${optionParam.Name}`}
                            key={`${formId}_${fieldName}_Text`}
                            variant="outlined"
                            value={selectedVertical?.label}
                            inputRef={register}
                            name={fieldName}
                        />
                    )}
                    fullWidth
                />
            } else if(optionParam.Name === "SubVerticalName"){
                return <Autocomplete
                    id={fieldId}
                    key={`${formId}_${fieldName}`}
                    onChange={(evt, value) => {
                        setSelectedSubVertical(
                            value
                        );
                    }}
                    getOptionLabel={option => `${option.label} - ${option.value}`}
                    options={SubVerticalList}
                    value={selectedSubVertical}
                    renderInput={params => (
                        <TextField
                            {...params}
                            label={`${optionParam.Name}`}
                            key={`${formId}_${fieldName}_Text`}
                            variant="outlined"
                            value={selectedSubVertical?.label}
                            inputRef={register}
                            name={fieldName}
                        />
                    )}
                    fullWidth
                />
            } else {
                return <Autocomplete
                    id={fieldId}
                    key={`${formId}_${fieldName}`}
                    options={optionParam.Options}
                    defaultValue={optionParam.Value || (optionParam.Options && optionParam.Options[0]) || ""}
                    renderInput={params => (
                        <TextField
                            {...params}
                            label={optionParam.Name}
                            key={`${formId}_${fieldName}_Text`}
                            variant="outlined"
                            value={optionParam.Value || (optionParam.Options && optionParam.Options[0]) || ""}
                            inputRef={register}
                            name={fieldName}
                        />
                    )}
                    fullWidth
                />
            }
            
        }  else if(optionParam.Type === "Datetime") {
            // console.log("DateTime", optionParam, DateTime.fromFormat(optionParam.Value || currentTime, 'yyyy-MM-dd HH:mm:ss').toFormat('MMM dd, yyyy hh:mm:ss a'));
            return (
                <MuiPickersUtilsProvider utils={LuxonUtils}>
                    <Controller
                        render={({ ref, ...rest }) => {
                            return <DateTimePicker
                                {...rest}
                                ampm={true}
                                openTo="date"
                                views={["month", "date", "year", "hours", "minutes", "seconds"]}
                                format="MMM dd, yyyy hh:mm:ss a"
                                label={optionParam.Name}
                                variant="inline"
                                inputVariant="outlined"
                                fullWidth
                                key={`${formId}_${fieldName}`}
                            />
                        }}
                        name={fieldName}
                        control={control}
                        defaultValue={DateTime.fromFormat(optionParam.Value || currentTime, 'yyyy-MM-dd HH:mm:ss').toFormat('MMM dd, yyyy hh:mm:ss a')}
                    />
                </MuiPickersUtilsProvider>
            );
        } else if(optionParam.Type === "Date") {
            return (
                <MuiPickersUtilsProvider utils={LuxonUtils}>
                    <Controller
                        render={({ ref, ...rest }) => {
                            return <DatePicker
                                {...rest}
                                openTo="date"
                                views={["month", "date", "year"]}
                                format="yyyy-MM-dd"
                                label={optionParam.Name}
                                inputVariant="outlined"
                                orientation="portrait"
                                fullWidth
                                key={`${formId}_${fieldName}`}
                            />
                        }}
                        name={fieldName}
                        control={control}
                        defaultValue={DateTime.fromFormat(optionParam.Value || currentDate, 'yyyy-MM-dd')}
                    />
                </MuiPickersUtilsProvider>
            );
        } else if(optionParam.Name === "VerticalId" || optionParam.Name === "VerticalIdOps") {
            return <Autocomplete
                id={fieldId}
                key={`${formId}_${fieldName}`}
                onChange={(evt, value) => {
                    setSelectedVertical(
                        value
                    );
                }}
                getOptionLabel={option => `${option.label} - ${option.value}`}
                options={VerticalList}
                value={selectedVertical}
                renderInput={params => (
                    <TextField
                        {...params}
                        label={`${optionParam.Name}`}
                        key={`${formId}_${fieldName}_Text`}
                        variant="outlined"
                        inputRef={register}
                        name={fieldName}
                    />
                )}
                fullWidth
            />
        }else if(optionParam.Name === "SubVerticalId" || optionParam.Name === "SubVerticalIdOps") {
            return <Autocomplete
                id={fieldId}
                key={`${formId}_${fieldName}`}
                onChange={(evt, value) => {
                    setSelectedSubVertical(
                        value
                    );
                }}
                getOptionLabel={option => `${option.label} - ${option.value}`}
                options={SubVerticalList}
                value={selectedSubVertical}
                renderInput={params => (
                    <TextField
                        {...params}
                        label={`${optionParam.Name}`}
                        key={`${formId}_${fieldName}_Text`}
                        variant="outlined"
                        inputRef={register}
                        name={fieldName}
                    />
                )}
                fullWidth
            />
        }else {
            return <TextField
                id={fieldId}
                key={`${formId}_${fieldName}`}
                label={optionParam.Name}
                type={optionParam.Type === 'int' ? 'number' : 'text'}
                variant="outlined"
                defaultValue={optionParam.Value}
                inputRef={register}
                name={fieldName}
                fullWidth
            />
        }
    }

    const triggerReportSearch = (data: any) => {
        let newFormData = { ...formState.formData } ;
        let variables = { ...formState.formData.RestAttr };

        let newFormDataParams = [];
        
        Object.keys(data).map(
            (v:any, index:any)=>{
                switch(v) {                    
                    case "VerticalId":
                    case "VerticalIdOps":
                        variables = {...variables, [v]:selectedVertical?.value};    
                      break;
                    case "VerticalName":
                        variables = {...variables, [v]:selectedVertical?.label};    
                      break;
                    case "SubVerticalId":
                    case "SubVerticalIdOps":                    
                        variables = {...variables, [v]:selectedSubVertical?.value};
                      break;
                    case "SubVerticalName":
                        variables = {...variables, [v]:selectedSubVertical?.label};
                      break;
                    case "StartDate":
                    case "EndDate":                        
                        if (DateTime.isDateTime(data[v]))
                            variables = {...variables, [v]: data[v].toFormat("yyyy-MM-dd HH:mm:ss")};
                        else
                            variables = {...variables, [v]: DateTime.fromFormat(data[v], "MMM dd, yyyy hh:mm:ss a").toFormat("yyyy-MM-dd HH:mm:ss")};
                      break;                            
                    default:                         
                        variables = {...variables, [v]:data[v]};
                }
                                
                // if(DateTime.isDateTime(data[v])){
                //     variables = {...variables, [v]: data[v].toFormat("yyyy-MM-dd HH:mm:ss")};
                // } else if(data[v].Type === "DateTime"){
                //     variables = {...variables, [v]: DateTime.fromFormat(data[v], "MMM dd, yyyy hh:mm:ss a").toFormat("yyyy-MM-dd HH:mm:ss")};
                // } else if(v === "VerticalId" || v === "VerticalIdOps"){
                //     variables = {...variables, [v]:selectedVertical?.value};
                // }else if(v === "SubVerticalId" || v === "SubVerticalIdOps"){
                //     variables = {...variables, [v]:selectedSubVertical?.value};
                // } else if(v === "VerticalName"){
                //     variables = {...variables, [v]:selectedVertical?.label};
                // }else if(v === "SubVerticalName"){
                //     variables = {...variables, [v]:selectedSubVertical?.label};
                // }else {
                //     variables = {...variables, [v]:data[v]};
                // }
            }
        );

        newFormDataParams = newFormData.Params.map((v:any)=>{
            return({...v, Value: variables[v.Name]});
        });

        newFormData = { ...newFormData, Params: newFormDataParams, Triggered: true};

        // console.log(newFormData, newFormDataParams);

        if(selectedReport){
            // const md5 = crypto.createHash("md5");
            // md5.update(JSON.stringify(variables));
            // setReportRequestId( md5.digest("hex") );
            dispatch && dispatch(updateForm({formId: formId, formData: newFormData}));
        }
        
    }

    return (
        <form onSubmit={handleSubmit(triggerReportSearch)} style={{ backgroundColor: "#fff" }}>
            <Grid container spacing={2}>
                {reportFormInputFields}
                <Grid item xs={12} lg={3}>
                    <Button variant="contained" size="large" color="primary" fullWidth style={{ height: "100%" }} type="submit">
                        Run Report
                    </Button>
                </Grid>
            </Grid>
        </form>
        
    );
};

const mapStateToProps = (
    state: RootState,
    ownProps: Props,
) => ({
    reportsTabs: state.tabsSection,
    formState: state.formsSection.formContainers[ownProps.formId],
    entityDataCache: state.entityDataCache,
});

export default connect(mapStateToProps, null)(ReportsSearchForm);
