import gql from "graphql-tag";

export const GET_REPORT_SCHEDULE_BY_BUYER = gql`
  query GetScheduleByBuyer($buyerId: Int, $date: Date) {
    LDPIngestQueryGroup {
      GetScheduleByBuyer(Date: $date, BuyerId: $buyerId) {
        BuyerId
        BuyerName
        Cap
        Close
        ContractCapacityId
        ContractCapacityPrice
        ContractId
        ContractName
        ContractType
        CreditBalance
        DefaultPrice
        Errors
        IsActive
        IsPing
        LDPDate
        Open
        Pings
        Priority
        RejctedPings
        ScheduleDay
        Sold
        StartEndTimezone
      }
    }
  }
  `;