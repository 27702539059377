import React, { useEffect, useState } from "react";
import {
    useModal,
    StatusIcon
} from "../../components";
import { Box, Grid, Typography } from "@material-ui/core";

import LDPUIDataTable from "../../components/LDPUIDataTable";
import { EllipsisTooltip2, useEllipsisStyles } from "../../components/tooltip";
import { buyerFilter, createDateFilter, numberColumnFilter, rptFilter, genericFilter, stringColumnFilter } from "../../components/customMuiDatatableFilter";

interface ReportResultListProps {
    resultId: string;
    data: Array<any>;
    customRenderers?: {
        [prop: string]: (value: any) => JSX.Element;
    }
}

const commonFilters:any = {
    Buyer: buyerFilter,
    CreatedDate: createDateFilter(),
    ModifiedDate: createDateFilter(),
    LeadCreationDate: createDateFilter(),
    LdpFinalDate: createDateFilter(),
    TransmitDate: createDateFilter(),
    Amount: rptFilter,
    BuyerContractId: numberColumnFilter,
    ProviderName: stringColumnFilter,
    RejectedReason: stringColumnFilter,
    ResponseDispositionName: stringColumnFilter,
    VerticalId: numberColumnFilter,
    SubVerticalId: numberColumnFilter,
    IsPing: genericFilter,
    IsPost: genericFilter,
    IsActive: genericFilter,
}

const ReportResultList = ({ data, customRenderers, resultId }: ReportResultListProps) => {
    const EllipsisTooltip = EllipsisTooltip2(648);
    const classes = useEllipsisStyles();
    const { Modal, closeModal, openModal } = useModal(undefined, 0.9);

    const [reportColumnsMUI, setReportColumnsMUI] = useState<Array<any>>(
        [{
            name: "NoResult",
            label: "- Result is empty. -",
            options: {
                customBodyRender: () => {
                    return <></>
                }
            }
        }]
    );

    const customerRendererFilters = ["BuyerUpdateResponse", "txresponse", "RejectedPayload", "RejectedReason"];

    // const disabledFilterList = ["CreatedDate", "ModifiedDate", "RejectedPayload"];

    useEffect(() => {
        // console.log("reports results list, data arrived", data);
        //data changed

        //parse first object
        if (data.length > 0) {
            const newColumns: any[] = [];
            const columns = Object.keys(data[0]);

            //console.log("records columns", columns);
            columns.forEach(column => {
                const columnConfig: any = {
                    "name": column,
                    "label": column,
                    "options": {
                        "display": true,
                        "filter": false, // (!disabledFilterList.includes(column)),
                        // ...genericFilter,
                        customBodyRender: (value: any) => {
                            if (typeof value === "boolean") {
                                return <StatusIcon value={value} />;
                            }
                            return value
                        },
                    }
                };

                if(commonFilters && commonFilters[column]){                    
                    columnConfig.options = {
                        ...columnConfig.options,
                        filter: true,
                        ...commonFilters[column]
                    }
                }
                
                if(customRenderers && customRenderers[column]){                    
                    columnConfig.options.customBodyRender = customRenderers[column];
                }

                if (customerRendererFilters.indexOf(column) > -1) {
                    columnConfig.options.customBodyRender = (value: any) => (
                        <EllipsisTooltip
                            title={<Typography color="inherit">{value}</Typography>}
                            placement="top"
                        >
                            <span className={`${classes.ellipsisColumn} ${classes.ellipsisColumnReportRunLiveList}`}>
                                {value}
                            </span>
                        </EllipsisTooltip>
                    );
                }

                newColumns.push(columnConfig);
            });

            //console.log("newColumns", newColumns);
            setReportColumnsMUI(newColumns);
        }
    }, [data]);

    const options: any = {
        filterType: "checkbox",
        selectableRows: "none",
        responsive: "vertical"
    };

    return (
        <>
            {!data ? <></> : (
                <LDPUIDataTable
                    ldpTableId={`${resultId}-report-result-list`}
                    restoreFilters={false}
                    title={<Typography variant='h6'> Report Result </Typography>}
                    data={data}
                    columns={reportColumnsMUI}
                    options={options}
                />
            )}
            <Modal />
        </>
    );
};

export default ReportResultList;
