import * as styles from "./reportScheduleByBuyer.module.css";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Box, Button, Grid, LinearProgress, Link, Paper, TextField, Typography } from "@material-ui/core";
import { RootState } from "../../../state";
import LuxonUtils from "@date-io/luxon";
import { Autocomplete } from "@material-ui/lab";
import { MuiPickersUtilsProvider, DateTimePicker, DatePicker } from "@material-ui/pickers";
import { Controller, useForm } from "react-hook-form";
import { ReportField, ReportOption, ReportOptionParam } from "../../../types/reports";
import crypto from "crypto";
import { dockForm, updateForm } from "../../../state/formReducer";
import { useLazyQuery, useQuery } from "@apollo/react-hooks";
import { GET_BUYERS } from "../../../common/models/buyers";
import { GetBuyers } from "../../../common/models/types/GetBuyers";
import { GET_REPORT_SCHEDULE_BY_BUYER } from "../../../common/models/reportScheduleByBuyer";
import { GetScheduleByBuyer } from "../../../common/models/types/GetScheduleByBuyer";
import { LDPUIDataTable, StatusIcon } from "../../../components";
import clsx from "clsx";
import { DateTime } from "luxon";

import { EllipsisTooltip, useEllipsisStyles } from "../../../components/tooltip";
import { genericFilter } from "../../../components/customMuiDatatableFilter";
import { GoogleUserMeta } from "../../../common/utils/googleUserMeta";
import { EmailCharBlockers, eventTracker } from "../../../components/tracker";

interface Props {
    dispatch?: Function;
    reportsTabs?: any;
    formState?: any;
    formId: string;
}

interface MappedSelectField {
    id: number;
    label: string;
    parent?: number;
    value?: string | null | undefined;
}

const ReportScheduleByBuyer = ({ reportsTabs, formState, dispatch, formId }: Props) => {
    const googleUserMeta = GoogleUserMeta();
    
    const classes = useEllipsisStyles();
    const [loading, setLoading] = useState(true);

    const { register, handleSubmit, watch, errors, control, getValues } = useForm();
    const {
        data: buyersData,
        error: buyersError,
        loading: buyersLoading
    } = useQuery<GetBuyers>(GET_BUYERS);
    
    const [ getScheduleByBuyer, {
        data: reportData,
        error: reportError,
        loading: reportLoading
    }] = useLazyQuery<GetScheduleByBuyer>(GET_REPORT_SCHEDULE_BY_BUYER);

    const [mappedBuyers, setMappedBuyers] = useState<MappedSelectField[]>();
    const [selectedBuyer, setSelectedBuyer] = useState<MappedSelectField | null>(null);
    
    useEffect(()=>{
        if(typeof formState === 'undefined'){
            //initialize formState
            let newFormData = { 
                BuyerId: 0,
                Date: DateTime.local().toFormat("yyyy-MM-dd"),
            };

            let formProps = {
                formId: formId,
                formTitle: '',
                formData: newFormData,
            };
            dispatch && dispatch(dockForm(formProps));
        } else {
            //call report API here
            if(formState.formData){
                getScheduleByBuyer({
                    variables: {
                        date: formState.formData.Date,
                        buyerId: formState.formData.BuyerId ?? 0,
                    }
                });

                eventTracker({
                    name: "Report Page",
                    caption: `Schedule By Buyer Executed`,
                    values: {
                        reportType: 'Schedule By Buyer',
                        parameters: {
                            date: formState.formData.Date,
                            buyerId: formState.formData.BuyerId ?? 0,
                        },
                        email: googleUserMeta?.email?.replace(/\@|\./g, it => EmailCharBlockers[it]) ?? null 
                    } 
                });
            }
        }
    }, [formState]);

  useEffect(() => {
    if (buyersData) {
      setMappedBuyers(
        buyersData?.LDPConfigQueryGroup?.Buyer?.map(buyer => {
          return { id: buyer?.BuyerId, label: `${buyer?.BuyerName} - ${buyer?.BuyerId}`, value: buyer?.BuyerName || null };
        })
      );
    }
  }, [buyersData, buyersError]);

  useEffect(() => {
    if(formState){
        setSelectedBuyer(
        mappedBuyers?.find(
            (buyer: MappedSelectField) => buyer?.id === formState.formData?.BuyerId
        ) || null
        );
        setLoading(false);
    }
  }, [mappedBuyers, formState]);

    const triggerReportSearch = (dataInput: any) => {
        let newFormData = { 
            BuyerId: mappedBuyers?.find(
                (buyer: MappedSelectField) => buyer?.label === dataInput.BuyerId)?.id,
            Date: dataInput.Date.toFormat("yyyy-MM-dd"),
        };

        dispatch && dispatch(updateForm({formId: formId, formData: newFormData}));
    }

    const options: any = {
        filterType: "multiselect",
        fullWidth: true,
        selectableRows: "none",
        responsive: "vertical",
        rowsPerPage: 50,
    };

    

    const reportColumnsMUI = [
        {
            name: "ContractId",
            label: "Contract ID",
            options: {
                display: false,
                filter: true,
                sort: true,
                ...genericFilter,
            },
        },
        {
            name: "ContractName",
            label: "Buyer Contract",
            options: {
                customBodyRender: (value: any, tableMeta: any) => {
                    const contractId = tableMeta.rowData[0];
                    return (<EllipsisTooltip
                            title={<Typography color="inherit">{value}</Typography>}
                            placement="top"
                        >                            
                            <Link className={`${classes.ellipsisColumn} ${classes.ellipsisColumnReportList}`} href={`/contracts/${contractId}`} color="inherit">{value}</Link>                            
                        </EllipsisTooltip>                                                  
                    );
                },
                filter: true,
                sort: true,
                ...genericFilter,
            },
        },
        {
            name: "BuyerId",
            label: "Buyer ID",
            options: {
                display: false,
                filter: true,
                sort: true,
                ...genericFilter,
            },
        },
        {
            name: "BuyerName",
            label: "Buyer",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value: any, tableMeta: any) => (
                    <EllipsisTooltip
                      title={<Typography color="inherit">{value}</Typography>}
                      placement="top"
                    >
                      <span className={`${classes.ellipsisColumn} ${classes.ellipsisColumnReportList}`}>
                        {value}
                      </span>
                    </EllipsisTooltip>
                  ),
                ...genericFilter,
            },
        },
        {
            name: "Priority",
            label: "Priority",
            options: {
                filter: false,
                sort: true
            },
        },
        {
            name: "DefaultPrice",
            label: "Default Price",
            options: {
                filter: true,
                sort: true,
                ...genericFilter,
            },
        },
        {
            name: "Open",
            label: "Open",
            options: {
                customBodyRender: (value: any) => <div className={styles.time}>{DateTime.fromFormat(value, "HH:mm:ss").toFormat("hh:mm A")}</div>,
                filter: true,
                sort: true,
                ...genericFilter,
            },
        },
        {
            name: "Close",
            label: "Close",
            options: {
                customBodyRender: (value: any) => <div className={styles.time}>{DateTime.fromFormat(value, "HH:mm:ss").toFormat("hh:mm A")}</div>,
                filter: true,
                sort: true,
                ...genericFilter,
            },
        },
        {
            name: "CreditBalance",
            label: "Credit Balance",
            options: {
                filter: true,
                sort: true,
                ...genericFilter,
            },
        },
        {
            name: "Sold",
            label: "Sold",
            options: {
                customBodyRender: (value: any, tableMeta: any) => {
                    //Sold = tableMeta.rowData[9], Cap = tableMeta.rowData[10]
                    const cap = tableMeta.rowData[10];

                    return (
                        <div 
                            className={
                                clsx(styles.sold, {
                                    [styles.sold0]: (value === 0),
                                    [styles.sold20]: (value > 0 && value < (cap/100)*20),
                                    [styles.sold21to79]: (value > (cap/100)*20 && value < (cap/100)*80),
                                    [styles.sold80]: (value > (cap/100)*79),
                                })
                            }
                        >
                            {value}
                        </div>
                    );
                },
                filter: true,
                sort: true,
                ...genericFilter,
            },
        },
        {
            name: "Cap",
            label: "Cap",
            options: {
                filter: false,
                sort: true,
                ...genericFilter,
            },
        },
        {
            name: "Errors",
            label: "Errors",
            options: {
                filter: true,
                sort: true,
                ...genericFilter,
            },
        },
        {
            name: "Pings",
            label: "Pings",
            options: {
                filter: true,
                sort: true,
                ...genericFilter,
            },
        },
        {
            name: "RejctedPings",
            label: "Rejected Pings",
            options: {
                filter: true,
                sort: true,
                ...genericFilter,
            },
        },
        {
            name: "IsPing",
            label: "Ping",
            options: {
              customBodyRender: (value: any) => <StatusIcon value={value} />,
              filter: true,
            //   filterOptions: {
            //     renderValue: (v: any) => (v ? "Yes" : "No")
            //   },
            //   customFilterListOptions: { render: (v: any) => (`IsPing: ${v ? "Yes" : "No"}`) },
            ...genericFilter,
            },
        },
        {
            name: "ContractCapacityId",
            label: "Contract Capacity ID",
            options: {
                display: false,
                filter: true,
                sort: true,
                ...genericFilter,
            },
        },
        {
            name: "ContractCapacityPrice",
            label: "Contract Capacity Price",
            options: {
                customBodyRender: (value: any) => parseFloat(value).toFixed(2),
                filter: false,
                sort: true
            },
        },
        {
            name: "IsActive",
            label: "IsActive",
            options: {
                customBodyRender: (value: any) => <StatusIcon value={value} />,
                filter: true,
                // filterOptions: {
                //     fullWidth: false,
                //     renderValue: (v: any) => (v ? "Yes" : "No")
                // },
                // customFilterListOptions: { render: (v: any) => (`IsActive: ${v ? "Yes" : "No"}`) },
                ...genericFilter,
            },
        },
        {
            name: "LDPDate",
            label: "LDPDate",
            options: {
                display: false,
                filter: false,
                sort: true,
            },
        },
        {
            name: "ScheduleDay",
            label: "Schedule Day",
            options: {
                filter: false,
                sort: true
            },
        },
        {
            name: "StartEndTimezone",
            label: "Start End Timezone",
            options: {
                display: false,
                filter: false,
                sort: true
            },
        },
        {
            name: "ContractType",
            label: "Type",
            options: {
                filter: true,
                sort: true,
                ...genericFilter,
            },
        },
    ]
    
    return (
        <Box p={3}>
            { formState && !loading &&
                <form onSubmit={handleSubmit(triggerReportSearch)} style={{ backgroundColor: "#fff" }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} lg={2}>
                            <Autocomplete
                                id="buyer-input"
                                options={mappedBuyers || []}
                                getOptionLabel={option => option.label}
                                value={selectedBuyer}
                                onChange={(event: any, newValue: MappedSelectField | null) => {
                                    setSelectedBuyer(newValue);
                                }}
                                // className={classes.input}
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        label="Buyer"
                                        variant="outlined"
                                        inputRef={register()}
                                        name="BuyerId"
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} lg={3}>
                            <MuiPickersUtilsProvider utils={LuxonUtils}>
                                <Controller
                                    render={({ ref, ...rest }) => {
                                        return <DateTimePicker
                                            {...rest}
                                            openTo="date"
                                            views={["month", "date", "year"]}
                                            format="yyyy-MM-dd"
                                            label="Date"
                                            inputVariant="outlined"
                                            orientation="portrait"
                                            fullWidth
                                            key={`${formId}_date-input`}
                                        />
                                    }}
                                    name="Date"
                                    control={control}
                                    defaultValue={formState.formData?.Date ? DateTime.fromFormat(formState.formData?.Date, 'yyyy-MM-dd') : DateTime.local()}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={3}>
                            <Button variant="contained" size="large" color="primary" fullWidth style={{ height: "100%" }} type="submit" disabled={reportLoading}>
                                Run Report
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            }
            {
                reportLoading ? <LinearProgress style={{marginTop: "10px"}}/> : 
                !reportData ? <></> :
                    <LDPUIDataTable
                        ldpTableId={`reports-${formId}-result`}
                        restoreFilters={false}
                        title={<Typography variant='h6'> Report Result </Typography>}
                        data={reportData?.LDPIngestQueryGroup?.GetScheduleByBuyer || []}
                        columns={reportColumnsMUI}
                        options={options}
                    />
            }

        </Box>
    );
};

const mapStateToProps = (
    state: RootState,
    ownProps: Props,
) => ({
    reportsTabs: state.tabsSection,
    formState: state.formsSection.formContainers[ownProps.formId],
});

export default connect(mapStateToProps, null)(ReportScheduleByBuyer);
