import { useLazyQuery } from "@apollo/react-hooks";
import { Box, Grid, LinearProgress } from "@material-ui/core";
import { RouteComponentProps, Router, useNavigate } from "@reach/router";
import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import { batch, connect } from "react-redux";
import { RUN_LIVE_REPORTS } from "../common/models/liveReport";
import {
  RunLiveReport,
  RunLiveReportVariables,
} from "../common/models/types/RunLiveReport";
import { GoogleUserMeta } from "../common/utils/googleUserMeta";
import { isRoleExternalAccountManager } from "../common/utils/roles";
import { Layout, PrivateRoute } from "../components";
import { TabProps } from "../components/tabs";
import {
  EmailCharBlockers,
  eventTracker as tracker,
} from "../components/tracker";
import { RootState } from "../state";
import { dockForm } from "../state/formReducer";
import { ReportOption, ReportOptionParam } from "../types/reports";
import {
  ReportPowerBI,
  ReportSidebar,
  SideLinksProps,
  useLiveReport,
} from "../views/reports";
import ReportResultList from "../views/reports/reportResultsList";
import * as pageCss from "../views/reports/reports.module.css";
import ReportSearchForm from "../views/reports/searchForm";

interface Props {
  dispatch: Function;
  reportsTabs: any;
  reportForms: any;
  reportsData: any;
  type: string;
  loggedInUser?: any;
}

const customReports = [
  // { DisplayName: 'Schedule by Buyer', Url : '/reports/schedulebybuyer',  ViewComponent: <ReportScheduleByBuyer formId="report-schedulebybuyer" />},
  // { DisplayName: 'Lead by Buyer', Url : '/reports/leadbybuyer',  ViewComponent: <ReportLeadByBuyer formId="report-leadbybuyer" />},
  // { DisplayName: 'Lead by Affiliate', Url : '/reports/leadbyaffiliate',  ViewComponent: <ReportLeadByAffiliate formId="report-leadbyaffiliate" />},
  {
    DisplayName: "PowerBI Reports",
    Url: "/reports/powerbi",
    ViewComponent: <ReportPowerBI formId="report-powerbi" />,
  },
];

const Reports = (props: Props & RouteComponentProps) => {
  const googleUserMeta = GoogleUserMeta();
  const navigate = useNavigate();

  const {
    reportsTabs,
    reportForms,
    dispatch,
    type: reportType,
    loggedInUser,
  } = props;

  const isUserExternalAccountManager = isRoleExternalAccountManager(
    loggedInUser
  );

  if (isUserExternalAccountManager) {
    return (
      <h2 style={{ color: "red", marginTop: "5rem" }}>
        Sorry you do not have access for this page right now.
      </h2>
    );
  }

  const [followUpWithEDW, setfollowUpWithEDW] = useState<boolean>(false);

  const [liveReportData, setLiveReportData] = useState<any>(null);
  const liveReport = useLiveReport(setLiveReportData);
  const [reportSidebarLinks, setReportSidebarLinks] = useState<
    SideLinksProps[]
  >(
    customReports.map((v) => {
      return { DisplayName: v.DisplayName, Url: v.Url };
    })
  );

  const [
    getLiveReport,
    {
      data: reportData,
      called: reportCalled,
      loading: reportLoading,
      error: reportError,
    },
  ] = useLazyQuery<RunLiveReport>(RUN_LIVE_REPORTS, {
    fetchPolicy: "network-only",
  });

  const runReportQuery = (fetchEDW?: boolean) => {
    if (
      reportForms.formContainers[`report-${reportType}`]?.formData?.Triggered
    ) {
      // console.log(`report change, reload data for ${reportType}`);
      const selectedReport: ReportOption<any> =
        reportForms.formContainers[`report-${reportType}`]?.formData;
      let edwStartDate = "";
      let edwTimezone = undefined;
      let FetchEDW = !!fetchEDW;

      const queryVars: RunLiveReportVariables & any = {
        StoredProcName: selectedReport.RestAttr.StoredProcName,
        DatabaseName: selectedReport.RestAttr.DatabaseName,
        FieldNames: [],
        FieldValues: [],
      };

      selectedReport.Params.forEach((v: ReportOptionParam) => {
        queryVars.FieldNames.push(v.Name);
        queryVars.FieldValues.push(v.Value);

        if (
          selectedReport.Name === "Report Sale Summary" ||
          selectedReport.Name === "Capacity Report Summary"
        ) {
          if (v.Name === "StartDate") edwStartDate = `${v.Value}`;
          if (v.Name === "TimeZone")
            edwTimezone = `${
              v.Value === "Pacific Standard Time" ? "US/Pacific" : ""
            }`;
        }
      });

      if (selectedReport.Name === "Capacity Report Summary") {
        queryVars.FieldNames.push("TimeZone");
        queryVars.FieldValues.push("Pacific Standard Time");
      }

      /** these are the currently the only ones with EDW modes */
      if (
        selectedReport.Name === "Report Sale Summary" ||
        selectedReport.Name === "Capacity Report Summary"
      ) {
        const startDateTzFmt = DateTime.fromFormat(
          edwStartDate,
          "yyyy-MM-dd HH:mm:ss",
          { zone: edwTimezone }
        );
        const edwDaysMargin = Number(process.env.FETCH_EDW_DAYS_CONDITION) || 3;
        const odsStartDateTzFmt = DateTime.now()
          .setZone(edwTimezone)
          .minus({ days: edwDaysMargin });

        FetchEDW = startDateTzFmt < odsStartDateTzFmt ? true : false;
      }

      getLiveReport({
        variables: {
          ...queryVars,
          FetchEDW,
        },
      });

      //console.log(`${selectedReport.Name} Executed`);
      tracker({
        name: "Report Page",
        caption: `${selectedReport.Name} Executed`,
        values: {
          reportType: selectedReport.Name,
          FetchEDW,
          parameters: [
            ...selectedReport.Params.map((p) => ({ [p.Name]: p.Value })),
          ],
          email:
            googleUserMeta?.email?.replace(
              /\@|\./g,
              (it) => EmailCharBlockers[it]
            ) ?? null,
        },
      });
    }
  };

  useEffect(() => {
    // console.log("reportData",reportData);
    if (reportData) {
      const jsonString = reportData?.LDPConfigQueryGroup?.RunLiveReport;
      const data: any[] = JSON.parse(
        (jsonString === "null" ? "[]" : jsonString) || "[]"
      );

      if (data.length === 0 && followUpWithEDW) {
        // if result is empty and EDW follow up is marked
        setfollowUpWithEDW(false);

        // run another query forcing EDW mode
        runReportQuery(true);
      } else {
        setLiveReportData(data);
      }
    }
  }, [reportData]);

  useEffect(() => {
    if (reportType) {
      setLiveReportData(null);
      if (
        reportForms.formContainers[`report-${reportType}`]?.formData?.Triggered
      ) {
        const selectedReport: ReportOption<any> =
          reportForms.formContainers[`report-${reportType}`]?.formData;

        setfollowUpWithEDW(
          !selectedReport.Params.find(
            (rop: ReportOptionParam) =>
              rop.Type?.toLowerCase() === "date" ||
              rop.Type?.toLowerCase() === "datetime"
          )
        );
        runReportQuery();
      }
    }
  }, [reportType, reportForms]);

  useEffect(() => {
    if (
      liveReport.reports.length > 0 &&
      reportSidebarLinks.length === customReports.length
    ) {
      let links = new Array();
      let reportFormProps = new Array();

      liveReport.reports.forEach((report) => {
        let linkReportType = report.Name.replace(/ /g, "").toLowerCase();
        //add default form data to each dynamic report

        let formProps = {
          formId: `report-${linkReportType}`,
          formTitle: "",
          formData: report,
        };

        reportFormProps.push(formProps);
        // https://suitedconnector.atlassian.net/browse/SAA-2631
        if (linkReportType === "reportsalesummary") {
          links.push({
            DisplayName: report.Name,
            Url: `/reports/${linkReportType}`,
          });
        }
      });

      const sidebarLinks = [...links, ...reportSidebarLinks];
      setReportSidebarLinks(sidebarLinks);

      //store default Report formData
      batch(() => {
        reportFormProps.forEach((formProps) => {
          if (
            typeof reportForms.formContainers[formProps.formId] === "undefined"
          ) {
            dispatch(dockForm(formProps));
          }
        });
      });

      if (!reportType) {
        navigate(sidebarLinks[0].Url);
      }
    }
  }, [liveReport.reports]);

  return (
    <Grid container className={pageCss.reportsWrapper} spacing={0}>
      <Grid item sm={12} md={4} lg={2} style={{ boxSizing: "unset" }}>
        <ReportSidebar links={reportSidebarLinks} />
      </Grid>
      <Grid item sm={12} md={8} lg={10}>
        {customReports.find((item) => item.Url === `/reports/${reportType}`)
          ?.ViewComponent || (
          <React.Fragment>
            <Box p={3}>
              {reportType && (
                <ReportSearchForm formId={`report-${reportType}`} />
              )}
              {reportLoading ? (
                <LinearProgress />
              ) : (
                liveReportData && (
                  <ReportResultList
                    data={liveReportData}
                    customRenderers={
                      reportForms.formContainers[`report-${reportType}`]
                        .formData?.DataRenderers
                    }
                    resultId={`reports-${reportType}-result`}
                  />
                )
              )}
            </Box>
          </React.Fragment>
        )}
      </Grid>
    </Grid>
  );
};

const ReportsWrapper = connect(
  (state: RootState) => ({
    reportForms: state.formsSection,
    loggedInUser: state.loggedInUser,
  }),
  null
)(Reports);

export default ({ location }: RouteComponentProps) => {
  return (
    <Layout>
      <Router
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <PrivateRoute
          default
          component={ReportsWrapper}
          path="/reports/"
          pagetitle="Reports"
        />
        <PrivateRoute
          component={ReportsWrapper}
          path="/reports/:type"
          pagetitle="Reports"
        />
      </Router>
    </Layout>
  );
};
