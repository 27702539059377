import React, { Fragment, useEffect, useState } from "react";
import { Box, Button, Grid, LinearProgress, Link, Paper, TextField, Typography } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import DatePicker from "react-datepicker";
import { useForm } from "react-hook-form";
import { GetBuyers } from "../../../common/models/types/getBuyers";

import * as styles from "./leadByBuyer.module.css";
import { GET_BUYERS } from "../../../common/models/buyers";
import { useLazyQuery } from "@apollo/react-hooks";
import { GetVertical } from "../../../common/models/types/GetVertical";
import { GET_SUBVERTICAL, GET_VERTICAL } from "../../../common/models/vertical";
import { GetSubVertical } from "../../../common/models/types/GetSubVertical";
import { dockForm, updateForm } from "../../../state/formReducer";
import { GetLeadByBuyer } from "../../../common/models/types/GetLeadByBuyer";
import { MUIDataTableColumn, MUIDataTableColumnDef } from "mui-datatables";
import { LDPUIDataTable, StatusIcon, useModal } from "../../../components";
import { RootState } from "../../../state";
import { connect } from "react-redux";
import { LdpJourneyDetails } from "../../opsScreen/ldpJourneyDetails";
import List from "@material-ui/icons/List";
import { dateToPreferredTimezone } from "../../../common/utils/date";
import { DateTime } from "luxon";

import { EllipsisTooltip, useEllipsisStyles } from "../../../components/tooltip";
import { genericFilter } from "../../../components/customMuiDatatableFilter";
import { GoogleUserMeta } from "../../../common/utils/googleUserMeta";
import { EmailCharBlockers, eventTracker } from "../../../components/tracker";
import { isEDW } from "../../../common/utils/isEDW";
import { GET_REPORT_LEAD_DETAIL_PAYLOAD } from "../../../common/models/reportLeadDetailPayload";

interface Props {
  dispatch?: Function;
  reportsTabs?: any;
  formState?: any;
  formId: string;
}

interface MappedSelectField {
  id: number;
  label: string;
  parent?: number;
  value?: string | null;
  name?:string | null;
}

const defaultCustomBodyRenderer = (value:any) => {
  if(typeof value === "boolean"){ 
    return <StatusIcon value={value} />;

  /* } if(typeof value === "number" || ( !isNaN(value) && !isNaN(parseFloat(value)) )){
    if(Number.isInteger(value)){
      return <NumberFormat value={value} displayType="text" thousandSeparator={true} />
    } else {
      return <NumberFormat value={Number(value).toFixed(2)} displayType="text" thousandSeparator={true} />
    }
 */
  } else {
    return value;
  }
}

const ReportLeadByBuyer = ({ reportsTabs, formState, dispatch, formId }: Props) => {
  const googleUserMeta = GoogleUserMeta();
  
  const classes = useEllipsisStyles();
  const [loading, setLoading] = useState(true);
  const { Modal, closeModal, openModal, setContent } = useModal({}, 0.8);
  const { register, handleSubmit, watch, errors, control, getValues } = useForm();

  const [ getLeadByBuyer, {
    data: reportData,
    error: reportError,
    loading: reportLoading
}] = useLazyQuery<GetLeadByBuyer>(GET_REPORT_LEAD_DETAIL_PAYLOAD);

  const [
    getAllBuyers,
    { data: buyersData, error: buyersError, loading: buyersLoading },
  ] = useLazyQuery<GetBuyers>(GET_BUYERS);

  const [mappedBuyers, setMappedBuyers] = useState<MappedSelectField[]>();

  const [
    getAllVertical,
    { data: verticalData, error: verticalError, loading: verticalLoading },
  ] = useLazyQuery<GetVertical>(GET_VERTICAL);

  const [mappedVertical, setMappedVertical] = useState<MappedSelectField[]>();

  const [
    getAllSubVertical,
    {
      data: subVerticalData,
      error: subVerticalError,
      loading: subVerticalLoading,
    },
  ] = useLazyQuery<GetSubVertical>(GET_SUBVERTICAL);

  const [subVerticals, setSubVerticals] = useState<MappedSelectField[]>();
  const [mappedSubVertical, setMappedSubVertical] = useState<MappedSelectField[]>();

  useEffect(() => {
    //console.log('get buyers');
    getAllBuyers();
  }, []);
  
  const preConfiguredColumns : {
    [columnName:string]:any
  } = {
    SignupId: {
      options: {
        filter: false,
      },
    },
    Age: {
      options: {
        filter: false,
      },
    },
    InsuranceAutoId: {
      label: "Lead ID",
      options: {
        filter: false,
      },
    },
    InsuranceHomeId: {
      options: {
        filter: false,
      },
    },
    Address: {
      options: {
        filter: false,
        customBodyRender: (value: any) => (
          <EllipsisTooltip
            title={<Typography color="inherit">{value}</Typography>}
            placement="top"
          >
            <span className={`${classes.ellipsisColumn} ${classes.ellipsisColumnReportList}`}>
              {value}
            </span>
          </EllipsisTooltip>
        ),      
      },
    },
    City: {
      options: {
        filter: false,
      },
    },
    State: {
      options: {
        filter: true,
        ...genericFilter,
      },
    },
    ZipCode: {
      options: {
        filter: false,
      },
    },
    Email: {
      options: {
        filter: false,
      },
    },
    PhoneNumber: {
      options: {
        filter: false,
      },
    },
    IpAddress: {
      options: {
        filter: false,
      },
    },
    
    UniversalLeadId: {
      options: {
        filter: false,
        customBodyRender: (value: any) => (
          <EllipsisTooltip
            title={<Typography color="inherit">{value}</Typography>}
            placement="top"
          >
            <span className={`${classes.ellipsisColumn} ${classes.ellipsisColumnReportList}`}>
              {value}
            </span>
          </EllipsisTooltip>
        ),      
      },
    },
    UUID: {
      options: {
        filter: false,
        customBodyRender: (value: any) => (
          <EllipsisTooltip
            title={<Typography color="inherit">{value}</Typography>}
            placement="top"
          >
            <span className={`${classes.ellipsisColumn} ${classes.ellipsisColumnReportList}`}>
              {value}
            </span>
          </EllipsisTooltip>
        ),      
      },
    },
    "FirstName": {
      options: {
        filter: false,
      },
    },
    "LastName": {
      options: {
        filter: false,
      },
    },
    "TrustedFormCertUrl": {
      options: {
        display: false,
        filter: false,
        sort: false
      },
    },
    "SignupUrl": {
      options: {
        display: false,
        filter: false,
        sort: false
      },
    },
    "CreatedDate": {
      options: {
        display: true,
        filter: false,
        sort: true,
        customBodyRender: (value:string) => {
          if(value && value.trim() !== ""){
            return dateToPreferredTimezone(value, "yyyy-MM-dd HH:mm:ss");
          } else {
            return value;
          }
        }
      },
    },
    "ModifiedDate": {
      options: {
        display: true,
        filter: false,
        sort: true,
        customBodyRender: (value:string) => {
          if(value && value.trim() !== ""){
            return dateToPreferredTimezone(value, "yyyy-MM-dd HH:mm:ss");
          } else {
            return value;
          }
        }
      },
    },
    "OptInDate": {
      options: {
        display: true,
        filter: false,
        sort: true,
        customBodyRender: (value:string) => {
          if(value && value.trim() !== ""){
            return dateToPreferredTimezone(value, "yyyy-MM-dd HH:mm:ss");
          } else {
            return value;
          }
        }
      },
    },
    "Date Sold": {
      options: {
        display: true,
        filter: false,
        sort: true,
        customBodyRender: (value:string) => {
          if(value && value.trim() !== ""){
            return dateToPreferredTimezone(value, "yyyy-MM-dd HH:mm:ss");
          } else {
            return value;
          }
        }
      },
    },
    "BirthDate": {
      options: {
        display: true,
        filter: false,
        sort: true,
        customBodyRender: (value:string) => {
          if(value && value.trim() !== ""){
            return dateToPreferredTimezone(value, "yyyy-MM-dd");
          } else {
            return value;
          }
        }
      },
    },
    "UserAgent": {
      options: {
        display: false,
        filter: false,
        sort: false
      },
    },
    "TcpaContent": {
      options: {
        display: false,
        filter: false,
        sort: false,
        customBodyRender: (value:string) => {
          return <Paper style={{
            height: "120px",
            width: "100%",
            overflowY: "auto",
            overflowX: "auto",
            padding: "5px",
            fontFamily: "monospace",
            boxShadow: "none",
          }}>{value}</Paper>
        }
      },
    },
    Accident: {
      options: {
        filterOptions: {
          renderValue: (v: any) => (!!v ? "Yes" : "No")
        },
        customFilterListOptions: { render: (v: any) => (`Accident: ${!!v ? "Yes" : "No"}`) },
      }
    },
    CurrentlyInsured: {
      options: {
        filterOptions: {
          renderValue: (v: any) => (!!v ? "Yes" : "No")
        },
        customFilterListOptions: { render: (v: any) => (`Insured: ${!!v ? "Yes" : "No"}`) },
      }
    },
    DoesRequireSR22: {
      options: {
        filterOptions: {
          renderValue: (v: any) => (!!v ? "Yes" : "No")
        },
        customFilterListOptions: { render: (v: any) => (`RequireSR22: ${!!v ? "Yes" : "No"}`) },
      }
    },
    Violation: {
      options: {
        filterOptions: {
          renderValue: (v: any) => (!!v ? "Yes" : "No")
        },
        customFilterListOptions: { render: (v: any) => (`Violation: ${!!v ? "Yes" : "No"}`) },
      }
    },
    DesireHomeownersInsurance: {
      options: {
        filterOptions: {
          renderValue: (v: any) => (!!v ? "Yes" : "No")
        },
        customFilterListOptions: { render: (v: any) => (`Homeowners Insurance: ${!!v ? "Yes" : "No"}`) },
      }
    },
    IsTestLead: {
      options: {
        filterOptions: {
          renderValue: (v: any) => (!!v ? "Yes" : "No")
        },
        customFilterListOptions: { render: (v: any) => (`Test Lead: ${!!v ? "Yes" : "No"}`) },
      }
    },
    DesireAutoWarranty: {
      options: {
        filterOptions: {
          renderValue: (v: any) => (!!v ? "Yes" : "No")
        },
        customFilterListOptions: { render: (v: any) => (`Auto Warranty: ${!!v ? "Yes" : "No"}`) },
      }
    },
  }

  useEffect(() => {
    if (buyersData) {
      //console.log('buyers loaded');

      const buyers = buyersData?.LDPConfigQueryGroup?.Buyer?.map(buyer => {
        return { 
          id: buyer?.BuyerId, 
          label: `${buyer?.BuyerName} - ${buyer?.BuyerId}`, 
          value: buyer?.BuyerName || null,
          name: buyer?.BuyerName
        };
      }).sort((a, b) => a.id - b.id) || [];
      setMappedBuyers(buyers);

      const buyerId = selectedBuyer?.id || formState?.formData?.BuyerId || false;
      if(!buyerId){        
        setSelectedBuyer({id: 0, label: "", value: ""});       
      } else {
        const buyer = buyers.find(b => b.id == buyerId);
        buyer && setSelectedBuyer(buyer);
      }
    }

    //console.log('get verticals');
    getAllVertical({
      variables: {
        where: `IsActive = true`,
      },
    });

  }, [buyersData, buyersError]);

  useEffect(() => {
    if (verticalData) {
      //console.log('verticals loaded');
      const verticalOptions = verticalData?.LDPConfigQueryGroup?.Vertical?.map(
        (x) => {
          return {
            id: x?.VerticalId,
            label: `${x?.VerticalName} - ${x?.VerticalId}`,
            name: x?.VerticalName
          };
        }
      ).sort((a, b) => a.id - b.id) || [];

      setMappedVertical(verticalOptions);

      const verticalId = selectedVertical?.id || formState?.formData?.VerticalId || false;
      if(!verticalId){
        setSelectedVertical(verticalOptions[0]);
      } else {
        const vertical = verticalOptions.find(o => o.id == verticalId);
        vertical && setSelectedVertical(vertical);
      }
      
    }

    //console.log('get sub verticals');
    getAllSubVertical({
      variables: {
        where: `IsActive = true`,
      },
    });

  }, [verticalData, verticalError]);

  useEffect(() => {
    if (subVerticalData) {
      //console.log('sub verticals loaded');
      const subVerticalOptions = subVerticalData?.LDPConfigQueryGroup?.SubVertical?.map(
        (x) => {
          return {
            parent: x?.VerticalId,
            id: x?.SubVerticalId,
            label: `${x?.SubVerticalName} - ${x?.SubVerticalId}`,
            name: x?.SubVerticalName
          };
        }
      ).sort((a, b) => a.id - b.id) || [];

      setSubVerticals(subVerticalOptions);

      const subVerticalId = selectedSubVertical?.id || formState?.formData?.SubVerticalId || false;
      if(!subVerticalId){
        const subVertical = subVerticalOptions.find(o => (selectedVertical && o.parent == selectedVertical?.id));
        if(subVertical){
          setSelectedSubVertical(subVertical);
        } else {
          setSelectedSubVertical(subVerticalOptions[0]);
        }
        
      } else {
        const subVertical = subVerticalOptions.find(o => o.id == subVerticalId && (!selectedVertical || (selectedVertical && o.parent == selectedVertical?.id)));
        if(subVertical){
          setSelectedSubVertical(subVertical);
        } else {
          setSelectedSubVertical(subVerticalOptions[0]);
        }
      }

    }

  }, [subVerticalData, subVerticalError]);

  const [selectedBuyer, setSelectedBuyer] = useState<MappedSelectField | null>({id: 0, label: "", value: ""});
  const [selectedVertical, setSelectedVertical] = useState<MappedSelectField | null>(null);
  const [selectedSubVertical, setSelectedSubVertical] = useState<MappedSelectField | null>(null);

  // form values may change, so we need a separate 
  // recording of this values for pop-up informations
  const [queryParams, setQueryParams] = useState<{
    buyerId: number,
    verticalId: number,
    subVerticalId: number
  }>({
    buyerId: 0,
    verticalId: 1,
    subVerticalId: 1
  });

  useEffect(() => {
    if(subVerticals && subVerticals?.length > 0){
      //console.log('selected vertical updated, updating sub vertical selection');
      const subVerticalOptions = subVerticals?.filter(sv => sv.parent == selectedVertical?.id).map(sv => ({
        id: sv?.id,
        label: sv?.label,
        name: sv.name,
        parent: sv.parent,
      })) || [];

      setMappedSubVertical(subVerticalOptions);
      setSelectedSubVertical(subVerticalOptions[0]);
    }
    
  }, [selectedVertical, subVerticals]);

  useEffect(()=>{
    //console.log('formstate effect', formState);

    if(typeof formState === 'undefined'){
        //initialize formState
        let newFormData = { 
            buyerId: 0,
            verticalId: 1,
            subVerticalId: 1,
            endDate: DateTime.local().endOf('day').toFormat("MM/dd/yyyy HH:mm:ss"),
            startDate: DateTime.local().startOf('day').toFormat("MM/dd/yyyy HH:mm:ss"),
        };

        let formProps = {
            formId: formId,
            formTitle: '',
            formData: newFormData,
        };
        dispatch && dispatch(dockForm(formProps));
    } else {
        //call report API here
        if(formState.formData){
          const buyerId = selectedBuyer?.id ?? formState.formData.buyerId ?? 0;
          const verticalId = selectedVertical?.id || formState.formData.verticalId;
          const subVerticalId = selectedSubVertical?.id || formState.formData.subVerticalId;
          const startDate = formState.formData.startDate || DateTime.local().startOf('day').toFormat("MM/dd/yyyy HH:mm:ss");
          const endDate = formState.formData.endDate || DateTime.local().endOf('day').toFormat("MM/dd/yyyy HH:mm:ss");   

          setStartDate(DateTime.fromFormat(startDate, "MM/dd/yyyy HH:mm:ss").toJSDate());
          setEndDate(DateTime.fromFormat(endDate, "MM/dd/yyyy HH:mm:ss").toJSDate());

          if(!!verticalId && !!subVerticalId){
            getLeadByBuyer({
              variables: {
                  startDate,
                  endDate,
                  verticalId,
                  subVerticalId,
                  buyerId
              }
            });

            setQueryParams({
              verticalId,
              subVerticalId,
              buyerId
            });

            eventTracker({
              name: "Report Page",
              caption: `Lead By Buyer Executed`,
              values: {
                  reportType: 'Lead By Buyer',
                  parameters: {
                    startDate,
                    endDate,
                    verticalId,
                    subVerticalId,
                    buyerId
                  },
                  email: googleUserMeta?.email?.replace(/\@|\./g, it => EmailCharBlockers[it]) ?? null 
              } 
            });
          }
          
        }
    }
  }, [formState]);

  const triggerReportSearch = (dataInput: any) => {
    
    let newFormData = { 
        buyerId: selectedBuyer?.id ?? 0,
        startDate: DateTime.fromJSDate(startDate).toFormat("MM/dd/yyyy HH:mm:ss"),
        endDate: DateTime.fromJSDate(endDate).toFormat("MM/dd/yyyy HH:mm:ss"),
        verticalId: selectedVertical?.id || 1,
        subVerticalId: selectedSubVertical?.id || 1,
    };

    setQueryParams({
      buyerId: newFormData.buyerId,
      subVerticalId: newFormData.subVerticalId,
      verticalId: newFormData.verticalId
    });

    dispatch && dispatch(updateForm({formId: formId, formData: newFormData}));
  }

  const [dataColumns, setDataColumns] = useState<MUIDataTableColumnDef[]>([]);
  const [tableData, setTableData] = useState<any[]>([]);
  useEffect(() => {
    if(reportData){
      const IdColumn = `${mappedVertical?.find(fld => fld.id == queryParams.verticalId)?.name}${mappedSubVertical?.find(fld => fld.id == queryParams.subVerticalId)?.name}Id`; //This will be the Lead ID Column

      // generate data columns
      const dataRows:any[] = JSON.parse(reportData.LDPIngestQueryGroup?.GetLeadDetailPayload || "[]");
      
      //remap reportData with extracted payload
      const remappedData = dataRows.map(item => {
        if(item.Payload){
          const payload = JSON.parse(item.Payload);
          delete item.Payload;
          return {...item, ...payload, [IdColumn]: item[IdColumn]};
        }
        else {
          return item;
        }
      });

      if(remappedData.length > 0){
        const columnDefs:MUIDataTableColumnDef[] = [];
        
        
        const fetchEDW = isEDW(startDate);

        Object.keys(remappedData[0]).forEach(columnName => {
          const columnDef: MUIDataTableColumn = {
            name: columnName,
            label: columnName,
            options: {
              display: true,
              filter: true,
              sort: true,
              ...genericFilter,
            },
            // overwrite only of pre configured column def exists
            ...(!!preConfiguredColumns[columnName] ? preConfiguredColumns[columnName] : {})
          };          
          if(!columnDef.options?.customBodyRender){                        
            columnDef.options = {
              ...columnDef.options,
              customBodyRender: defaultCustomBodyRenderer
            }
          }

          // some specific handling
          if(columnName.toLowerCase() === IdColumn.toLowerCase()){
            columnDef.label = "Lead ID";
            columnDef.options.customBodyRender = (value: string) => {
              return <Fragment>
                <Link href="#"
                  onClick={() => {
                      openModal({
                        icon: <List />,
                        iconColor: "rose",
                        content: <LdpJourneyDetails LeadDataId={value} VerticalId={queryParams.verticalId} SubVerticalId={queryParams.subVerticalId} FetchEDW={!!fetchEDW} />,
                        title: `LDP Journey Lead Data Id #${value} on Vertical ${queryParams.verticalId}, Sub-Vertical ${queryParams.subVerticalId}`
                      });
                  }}>
                  {value}
                </Link>
              </Fragment>
            }
          }

          columnDefs.push(columnDef);
        });
        setDataColumns(columnDefs);

      } else {
        setDataColumns([]);

      }

      setTableData(remappedData);
    } else {
      setDataColumns([]);
      setTableData([]);
    }
    
  }, [reportData, reportError]);

  // initial page load of report
  useEffect(() => {
    if(loading && selectedBuyer && selectedVertical && selectedSubVertical && formState){
      const buyerId = selectedBuyer?.id ?? formState.formData.BuyerId ?? 0;
      const verticalId = selectedVertical?.id || formState.formData.VerticalId;
      const subVerticalId = selectedSubVertical?.id || formState.formData.SubVerticalId;

      !!verticalId && !!subVerticalId && getLeadByBuyer({
            variables: {
                startDate: formState.formData.StartDate || DateTime.fromJSDate(startDate).toFormat("MM/dd/yyyy HH:mm:ss"),
                endDate: formState.formData.EndDate || DateTime.fromJSDate(endDate).toFormat("MM/dd/yyyy HH:mm:ss"),
                verticalId,
                subVerticalId,
                buyerId
            }
        });
      setLoading(false);
    }
  }, [selectedBuyer, selectedVertical, selectedSubVertical, formState]);

  const [startDate, setStartDate] = useState<Date>(DateTime.local().startOf('day').toJSDate());
  const [endDate, setEndDate] = useState<Date>(DateTime.local().endOf('day').toJSDate());

  return (
    <Box p={3}>
        { formState && !loading &&
          <form onSubmit={handleSubmit(triggerReportSearch)} style={{ backgroundColor: "#fff" }}>
              <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} lg={2}>
                      <Autocomplete
                          id="buyer-input"
                          options={mappedBuyers || []}
                          getOptionLabel={option => option.label}
                          value={selectedBuyer}
                          onChange={(event: any, newValue: MappedSelectField | null) => {                              
                              setSelectedBuyer(newValue);
                          }}
                          // className={classes.input}
                          renderInput={params => (
                              <TextField
                                  {...params}
                                  label="Buyer"
                                  variant="outlined"
                                  inputRef={register()}
                                  name="BuyerId"
                              />
                          )}
                      />
                  </Grid>

                  <Grid item xs={12} sm={6} lg={2}>
                      <Autocomplete
                          id="vertical-input"
                          options={mappedVertical || []}
                          getOptionLabel={option => option.label}
                          value={selectedVertical}
                          onChange={(event: any, newValue: MappedSelectField | null) => {
                              setSelectedVertical(newValue);
                          }}
                          renderInput={params => (
                              <TextField
                                  {...params}
                                  label="Vertical"
                                  variant="outlined"
                                  inputRef={register()}
                                  name="VerticalId"
                              />
                          )}
                      />
                  </Grid>

                  <Grid item xs={12} sm={6} lg={2}>
                      <Autocomplete
                          id="sub-vertical-input"
                          options={mappedSubVertical || []}
                          getOptionLabel={option => option.label}
                          value={selectedSubVertical}
                          onChange={(event: any, newValue: MappedSelectField | null) => {
                              setSelectedSubVertical(newValue);
                          }}
                          renderInput={params => (
                              <TextField
                                  {...params}
                                  label="Sub-Vertical"
                                  variant="outlined"
                                  inputRef={register()}
                                  name="SubVerticalId"
                              />
                          )}
                      />
                  </Grid>

                  
                  <Grid item xs={12} sm={6} lg={2}>
                    <DatePicker
                        id="start-date-input"
                        name="StartDate"
                        dateFormat={"MM/dd/yyyy hh:mm:ss a"}
                        onChange={(date) => {
                          setStartDate(date);
                        }}
                        showTimeSelect
                        selected={startDate}
                        customInput={
                          <TextField
                            label={`Start Date (PST)`}
                            variant="outlined"
                            inputRef={register()}
                            name="StartDate"
                            fullWidth
                          />
                        }
                      />
                    
                  </Grid>
                  <Grid item xs={12} sm={6} lg={2}>
                    <DatePicker
                      id="end-date-input"
                      name="EndDate"
                      dateFormat={"MM/dd/yyyy hh:mm:ss a"}
                      onChange={(date) => {
                        setEndDate(date);
                      }}
                      showTimeSelect
                      selected={endDate}
                      customInput={
                        <TextField
                          label={`End Date (PST)`}
                          variant="outlined"
                          inputRef={register()}
                          name="EndDate"
                          fullWidth
                        />
                      }
                    />
                    
                  </Grid>
                  

                  <Grid item xs={12} sm={6} lg={2}>
                      <Button variant="contained" size="large" color="primary" fullWidth style={{ height: "100%" }} type="submit" disabled={reportLoading}>
                          Run Report
                      </Button>
                  </Grid>
              </Grid>
          </form>
        }
        {
          reportLoading ? <LinearProgress style={{marginTop: "10px"}}/> : 
          !reportData ? <Typography variant='h6'> No Data Found </Typography> :
              <LDPUIDataTable
                  ldpTableId={`reports-${formId}-result`}
                  restoreFilters={false}
                  title={<Typography variant='h6'> Report Result </Typography>}
                  data={tableData}
                  columns={dataColumns}
                  options={{
                    filterType: "dropdown",
                    selectableRows: "none",
                    responsive: "vertical",
                    rowsPerPage: 50,
                }}
              />
        }

        <Modal />
     </Box>
  );
}

const mapStateToProps = (
  state: RootState,
  ownProps: Props,
) => ({
  reportsTabs: state.tabsSection,
  formState: state.formsSection.formContainers[ownProps.formId],
});

export default connect(mapStateToProps, null)(ReportLeadByBuyer);