import React, { useState, useEffect } from "react";
import { useLazyQuery, useQuery } from "@apollo/react-hooks";

import {
  Paper
} from "@material-ui/core";

import { GET_LIVE_REPORTS, RUN_LIVE_REPORTS } from "../../../common/models/liveReport";
import { GetLiveReports } from "../../../common/models/types/GetLiveReports";
import { RunLiveReport, RunLiveReportVariables } from "../../../common/models/types/RunLiveReport";
import { ReportOption, ReportOptionParam } from "../../../types/reports";
import { DateTime } from "luxon";
import { EmailCharBlockers, eventTracker } from "../../../components/tracker";
import { GoogleUserMeta } from "../../../common/utils/googleUserMeta";

interface LiveReportList {
  reports: ReportOption<RunLiveReport>[]
}

const useLiveReport = (updateData:(data:any) => void):LiveReportList => {
  const googleUserMeta = GoogleUserMeta();
  
  const { data, called, loading, error } = useQuery<GetLiveReports>(GET_LIVE_REPORTS);
  const query = useLazyQuery<RunLiveReport>(RUN_LIVE_REPORTS);
  
  const [ runLiveReport, { data: reportData, called: reportCalled, loading: reportLoading, error: reportError } ] = query;
  
  const availableReports:ReportOption<RunLiveReport>[] = [];
  const returnValue:LiveReportList = {
    reports: []
  };

  const [ followUpWithEDW, setfollowUpWithEDW ] = useState<any>(undefined);

  useEffect(() => {
    const result: any = JSON.parse(reportData?.LDPConfigQueryGroup?.RunLiveReport || "[]");
    if(result.length === 0 && followUpWithEDW){
      runLiveReport({
        variables: { ...followUpWithEDW, FetchEDW: true }
      });
      setfollowUpWithEDW(undefined);
    } else {
      if(reportData && !reportError) updateData(result);
    }
  }, [reportData] );

  useEffect(() => {
    //console.log("live report list update");

    if(data && !error){
      data.LDPConfigQueryGroup?.LiveReport?.forEach(report => {

        const params:ReportOptionParam[] = JSON.parse(report?.InputVariable || "[]");
        params.forEach(param => {
          if(param.Name === "StartDate"){
            param.Value = DateTime.local().startOf('day').minus({ days: 1 }).toFormat("yyyy-MM-dd") + " 00:00:00";
          } else if(param.Name === "EndDate") {
            param.Value = DateTime.local().startOf('day').minus({ days: 1 }).toFormat("yyyy-MM-dd") + " 23:59:59";
          }
        })

        availableReports.push({
          "Name": report?.ReportName || `Report ID ${report?.LiveReportId}`,
          RestAttr: {
            StoredProcName: report?.StoredProcName,
            DatabaseName: report?.DatabaseName
          },
          Query: query,
          RunReport: (vars:any, fetchEDW?:boolean) => {
            console.log("Running report", vars);
            
            //reconstruct vars into RunLiveReportVariables
            const queryVars:RunLiveReportVariables = {
              StoredProcName: vars.StoredProcName,
              DatabaseName: vars.DatabaseName,
              FieldNames: [],
              FieldValues: [],
              FetchEDW: !!fetchEDW, //default to false since this StoredProcName is not yet EDW supported
            };

            Object.keys(vars).forEach(key => {
              if(!(queryVars as any)[key]){
                queryVars.FieldNames?.push(key);
                queryVars.FieldValues?.push(vars[key]);
              }
            });

            // if fetch edw is unknown and query does not contain any date fields,
            // set to query with EDW later
            if(fetchEDW === undefined && vars.FetchEDW === undefined){
              setfollowUpWithEDW(queryVars);
            }
            
            runLiveReport({
              variables: queryVars
            });

            console.log(`${report?.ReportName} Executed`);
            eventTracker({
                name: "Report Page",
                caption: `${report?.ReportName} Executed`,
                values: {
                    reportType: report?.ReportName,
                    parameters: [...Object.keys(vars).map(p => ({ [p]: vars[p] }))],
                    email: googleUserMeta?.email?.replace(/\@|\./g, it => EmailCharBlockers[it]) ?? null 
                } 
            });
          },
          "Params": params,
          DataRenderers: {
            RequestPayload: (value:string) => {
              return <Paper style={{
                height: "120px",
                width: "100%",
                overflowY: "auto",
                overflowX: "auto",
                padding: "5px",
                fontFamily: "monospace",
                boxShadow: "none",
              }}>{value}</Paper>
            }
          },
          Triggered: false,
        });
      });

      returnValue.reports = availableReports;
    }
  }, [data]);

  return returnValue;
}

const Style: any = {
  CodeWrapper: {
    height: "120px",
    width: "100%",
    overflowY: "auto",
    overflowX: "auto",
    padding: "5px",
    fontFamily: "monospace",
    boxShadow: "none",
  },

  CodeModal: {
    height: "60vh",
    width: "100%",
    overflowY: "auto",
    overflowX: "auto",
    padding: "5px",
    fontFamily: "monospace",
    boxShadow: "none",
  },
};

export default useLiveReport;