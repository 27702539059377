import {
  Button, Grid, Paper
} from "@material-ui/core";
import React from "react";

import ListItem, { ListItemProps } from "@material-ui/core/ListItem";
import * as styles from "./reportSidebar.module.css";

import { useLocation } from "@reach/router";

export interface SideLinksProps {
  DisplayName: string | null;
  Url: string | null;
};

interface SidebarProp {
  links: Array<SideLinksProps> | undefined;
};

export const ReportSidebar = ({ links }: SidebarProp) => {

  const ListItemLink = (props: ListItemProps<"a", { button?: true }>) => {
    return <ListItem button component="a" {...props} />;
  };

  const location = useLocation();

  return (
    <Paper className={styles.drawerPaper}>
      <Grid container>
        {links?.map(({ DisplayName, Url }, i) => (
          <Grid item xs={12} sm={6} md={12} style={{ padding: "2px" }}>
            <Button href={Url || "#"} key={i} fullWidth variant={!!Url && location.pathname == Url ? "contained" : "text"} size="large" style={{ justifyContent: "left", textTransform: "none" }}>
              {DisplayName}
            </Button>
          </Grid>
        ))}
      </Grid>
    </Paper>
  );
};

