import gql from "graphql-tag";

export const GET_REPORT_LEAD_DETAIL_PAYLOAD = gql`
  query GetLeadDetailPayload(
    $startDate: DateTime!
    $endDate: DateTime!,
    $verticalId: Int!,
    $subVerticalId: Int!,
    $buyerId: Int! = 0,
    $affiliateId: Int! = 0) {
    LDPIngestQueryGroup {
      GetLeadDetailPayload(
        StartDate: $startDate, 
        EndDate: $endDate, 
        VerticalId: $verticalId, 
        SubVerticalId: $subVerticalId,
        BuyerId: $buyerId,
        AffiliateId: $affiliateId
      )
    }
  }
  `;