import { Box } from "@material-ui/core";
import React from "react";
import { connect } from "react-redux";
import { RootState } from "../../../state";

interface Props {
  formId: string;
}

const ReportPowerBI = () => {
  return (
    <Box p={3}>
      <iframe
        title="LDP Report"
        width="100%"
        height="700px"
        src="https://app.powerbi.com/reportEmbed?reportId=4030c9d5-9110-4564-99f9-c70853e62984&autoAuth=true&ctid=905090bf-21fe-40fc-aaa9-7edf838f116b"
        frameBorder="0"
        // allowFullScreen="true"
      ></iframe>
    </Box>
  );
};

const mapStateToProps = (state: RootState, ownProps: Props) => ({
  reportsTabs: state.tabsSection,
  formState: state.formsSection.formContainers[ownProps.formId],
});

export default connect(mapStateToProps, null)(ReportPowerBI);
